import React from "react"
import { graphql } from "gatsby"
import LocalizedLink from "../components/localizedLink"

const graphQLAllHeadersAndFooters = require("../utils/queries/allHeadersAndFooters")

const NotFound = ({ data, locale = "en-gb" }) => {
  return (
    <>
      <div className="columns is-centered">
        <div className="column has-text-centered is-6 my-6">
          <h1 className="title is-5">404 Not Found...</h1>
          <div className="subtitle is-6">
            <a href="/" className="button is-rounded">
              Back to home page
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound

export const query404 = graphql`
  query AllHeaders404 {
    prismic {
      allHeaders {
        edges {
          node {
            alert_message
            header_call_to_action {
              header_call_to_action_id
              header_call_to_action_name
              header_call_to_action_link {
                ... on PRISMIC__Document {
                  _meta {
                    uid
                  }
                }
              }
            }
            header_social_title
            header_social {
              header_social_id
              header_social_link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
            header_logo_image
            _meta {
              lang
            }
            body {
              __typename
              ... on PRISMIC_HeaderBodyMenu_element {
                type
                label
                primary {
                  menu_element_id
                  menu_element_name
                  menu_element_link {
                    _linkType
                    ... on PRISMIC__Document {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_HeaderBodyMenu_element_with_subelements {
                type
                primary {
                  menu_group_id
                  menu_group_name
                }
                fields {
                  menu_group_element_id
                  menu_group_element_name
                  menu_group_element_link {
                    _linkType
                    ... on PRISMIC__Document {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }

      allFooters {
        edges {
          node {
            _meta {
              uid
              lang
            }
            copyright
            column_title
            link_app_1 {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            image_app_1
            link_app_2 {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            image_app_2
            body {
              ... on PRISMIC_FooterBodyCategory {
                primary {
                  category_id
                  category_name
                }
                fields {
                  footer_element_id
                  footer_element_name
                  footer_element_link {
                    _linkType
                    ... on PRISMIC__Document {
                      _meta {
                        uid
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
